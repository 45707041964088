




import { Vue, Component } from "vue-property-decorator";
import SettingsTemplate from "@/components/settings/SettingsTemplate.vue";
import sidebarConstants from "@/shared/constants/sidebar-settings";

@Component({
  components: {
    "settings-template": SettingsTemplate
  }
})
export default class DocumentsSettings extends Vue {
  private sidebarItem = sidebarConstants.documentSettings;
}
